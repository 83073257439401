@import './fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;

.main-bg {
    background-image: url('./assets/images/herobg-ecbfddc8.png');
    background-size:cover;
    background-repeat: no-repeat;
  }
  
  /* Styles for medium and larger screens */
  @media (min-width: 768px) {
    .main-bg {
      background-size: contain;
    }
  }


/* h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #fff;
} */

h2 {
    @apply font-proponoBc text-3xl font-bold text-slate-400;
    display: block;
    /* @apply text-3xl; */
    /* @apply text-slate-400; */
    letter-spacing: 0.15rem;
    font-weight: 600;
    color: #fff;
}

p {
    font-family: 'Poppins', sans-serif;
    @apply font-proponoBc text-slate-400;
    font-size: 1.2rem;
    font-weight: 800;
    color: #fff;
}

.max-w-screen {
    max-width: 100dvw;
    margin: 0 auto;
}

.fade-vertical-line {
    background: linear-gradient(to bottom, #915EFF, rgba(145, 94, 255, 0));
}

.font-poppins {
    font-family: 'Poppins', sans-serif;
}
  
  @keyframes blink {
    from, to {
      background-color: transparent;
    }
    50% {
      background-color: black;
    }
  }

.cursor {
    display: inline-block;
    width: 1px;
    background-color: black;
    animation: blink 1s step-end infinite;
  }

.card {
    transition: all 0.5s ease-in-out;
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    cursor: pointer;
}

.card.expanded {
width: 400px;
height: 300px;
}

.gradient-border {
    position: relative;
    padding: 5px; /* Adjust padding to make space for the border */
    border-radius: 10px; /* Adjust border radius as needed */
    background: white; /* Background color of the card */
}

.gradient-border::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    border-radius: inherit; /* Match the border radius of the card */
}

.bg-cover {
    background-size: cover
}

.bg-center {
    background-position: center
}

.bg-no-repeat {
    background-repeat: no-repeat
}

.bg-hero-pattern {
    background-image: url('./assets/images/herobg-ecbfddc8.png')
}

.centered-container {
    display: flex;
    align-items: center;
    position: relative;
}

.centered-vr {
    position: absolute;
    width: 2px;
    height: 80%;
    background-color: #ccc;
    border-radius: 8px;
}

.list-disc {
    list-style-type: disc
}

.absolute-position-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
}

.timeline-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .timeline-line {
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: white;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .timeline-element {
    position: relative;
    width: 40%;
    background-color: #1d1836;
    color: white;
    padding: 1px;
    margin: 1rem 0;
    @apply rounded-2xl;
  }
  
  .timeline-element::before {
    content: '';
    position: absolute;
    top: -10px; /* Adjust as needed */
    border-width: 10px;
    border-style: solid;
  }

  .timeline-element.left::before {
    left: 100%;
    top: 40%;
    border-color: transparent transparent transparent #915EFF ;
  }
  
  .timeline-element.right::before {
    right: 100%;
    top: 40%;
    border-color: transparent #22c55e  transparent transparent;
  }

  .timeline-element.left::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    left: 104%;
    top: 42%; /* Adjust to align with the arrows */
  }

  .timeline-element.right::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    right: 104%;
    top: 42%; /* Adjust to align with the arrows */
  }
  
  .timeline-element.left {
    align-self: flex-start;
    margin-left: 8%;
  }
  
  .timeline-element.right {
    align-self: flex-end;
    margin-right: 8%;
  }

  @media (max-width: 768px) {

    .timeline-container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .timeline-element {
        position: relative;
        width: 100%;
        background-color: #1d1836;
        color: white;
        padding: 1px;
        margin: 1rem 0;
        @apply rounded-2xl;
        left: 15%;
    }

    .timeline-line {
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: white;
        left: 0%;
        transform: translateX(-50%);
    }

    .timeline-element.left::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #FFF;
        border-radius: 50%;
        left: -8%;
        top: 42%; /* Adjust to align with the arrows */
    }

    .timeline-element.right::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #FFF;
        border-radius: 50%;
        left: -8%;
        top: 42%; /* Adjust to align with the arrows */
    }
        
    .timeline-element.left {
        align-self: flex-end;
        margin-right: 8%;
    }
  
    .timeline-element.left::before {
        left:-5%;
        top: 40%;
        border-color: transparent #22c55e  transparent transparent;
    }
    
    .timeline-element.right::before {
        left: -10%;
        /* right: 100%; */
        top: 40%;
        border-color: transparent #22c55e  transparent transparent;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
    width: 12px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #5e09e7;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #5e09e7;
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #5e09e7;
  }
  
  /* Firefox */
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #5e09e7 #5e09e7;
  }

