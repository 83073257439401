@font-face {
  font-family: 'bungee';
  src: url('./assets/fonts/bungee.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'propono-bc';
  src: url('./assets/fonts/propono-bc.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}